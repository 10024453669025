<template>
  <div class="main-container">
    <div class="section--header">
      <div class="header-action">
        <span><i class="fal fa-users-medical"></i></span>
        {{ $t("scheduleAttEmp.title") }}
      </div>
    </div>

    <div class="section-action">
      <v-row>
        <v-col cols="12" lg="3" md="3">
          <label class="label-input">{{ $t("Employee.search_filter") }}</label>
          <v-text-field
            class="btn btn-color btn-border btn-border-color"
            outlined
            dense
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('Search.search')"
            v-model="employee_name"
            @keypress.enter="fetchAllEmployee"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="3" lg="3">
          <label class="label-input">{{ $t("ScanPoint.dept") }}</label>
          <v-autocomplete
            filled
            dense
            item-text="name"
            item-value="id"
            outlined
            :items="listDepartment"
            hide-selected
            v-model="department_id"
            @change="fetchAllEmployee"
          >
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.name"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="3" lg="3">
          <label class="label-input">{{ $t("ScanPoint.branch") }}</label>
          <v-autocomplete
            filled
            dense
            item-text="branch_name"
            item-value="id"
            outlined
            :items="listBranch"
            hide-selected
            v-model="branch_id"
            @change="fetchAllEmployee"
          >
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.branch_name"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="2" lg="2">
          <div class="mt-6">
            <v-btn class="ma-2 btn" color="info" @click="AllEmployee">
              All
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="section--content">
      <div class="section-left">
        <v-form class="form-autocomplete">
          <v-row>
            <v-col cols="12" md="12">
              <v-autocomplete
                filled
                chips
                color="blue-grey lighten-4"
                label="Select Employee"
                item-text="name"
                item-value="id"
                multiple
                outlined
                :items="allEmployee"
                hide-selected
                v-model="data.employee"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="removeItemFromTable(data.item)"
                  >
                    <v-avatar left>
                      <v-img :src="data.item.urlProfile"></v-img>
                    </v-avatar>
                    {{ data.item.name }} {{ data.item.surname }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-avatar>
                      <img :src="data.item.urlProfile" alt="" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.surname"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </div>
      <div class="section-right" v-if="selectedEmployees.length > 0">
        <div class="show-list-emp-position">
          <table class="table table-view">
            <tbody>
              <tr
                v-for="(item, idx) in selectedEmployees"
                :key="idx"
                class="text-start"
              >
                <td>{{ idx + 1 }}</td>
                <td class="image-circle-emp-position">
                  <img :src="item.urlProfile" alt="" />
                </td>
                <td class="employee-name">
                  <span>{{ item.name }} {{ item.surname }}</span>
                </td>
                <td></td>
                <td></td>
                <td class="text-end">
                  <v-menu transition="slide-y-transition" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="removeItemFromTable(item)"
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      >
                        <i
                          style="font-size: 22px"
                          class="fas fa-minus-circle"
                        ></i>
                      </v-btn>
                    </template>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <v-btn
          class="btn-save-change btn-right"
          @click="saveChange()"
          v-if="selectedEmployees.length > 0"
        >
          {{ $t("scheduleAttEmp.save") }}
        </v-btn>
      </div>
    </div>
    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import Loading from "@/components/Loading";
export default {
  data() {
    return {
      allEmployee: [],
      attractEmployee: [],
      data: {
        employee: [],
      },
      listDepartment: [],
      listBranch: [],
      department_id: "",
      branch_id: "",
      isLoading: false,
      employee_name: "",
    };
  },
  computed: {
    selectedEmployees() {
      return this.data.employee.map((id) => {
        const find = this.allEmployee.filter((item) => {
          return id === item.id;
        });
        if (find.length) {
          return find[0];
        }
        return {};
      });
    },
  },
  components: {
    Loading,
  },

  methods: {
    fetchAllEmployee() {
      this.data.employee = [];
      this.isLoading = true;
      this.$axios
        .get(`company/all-employee/add-roster`, {
          params: {
            name: this.employee_name,
            department_id: this.department_id,
            branch_id: this.branch_id,
          },
        })
        .then((res) => {
          this.isLoading = false;
          if (res.status == 200) {
            if (res.data.data.length == 0) {
              setTimeout(() => {
                this.$notification.OpenNotification(
                  "ແຈ້ງເຕືອນ.",
                  "ບໍມີຂໍ້ມູນພະນັກງານ.",
                  "top-right",
                  "danger",
                  3000
                );
              }, 300);
            }
            this.allEmployee = res.data.data;
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    AllEmployee() {
      this.department_id = "";
      this.branch_id = "";
      this.fetchAllEmployee();
    },
    fetchDepartment() {
      this.$axios.get(`company/list/departments/selected`, {}).then((res) => {
        if (res.status === 200) {
          this.listDepartment = res.data.data;
        }
      });
    },
    fetchBranch() {
      this.$axios
        .get(`company/list/company/branches`)
        .then((res) => {
          if (res) {
            this.listBranch = res.data.companyBranches;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    removeItemFromTable(item) {
      const index = this.data.employee.indexOf(item.id);
      if (index >= 0) {
        this.data.employee.splice(index, 1);
      }
    },
    saveChange() {
      const id = this.$route.params.schedule_id;
      const results = this.selectedEmployees.map((item) => {
        return item.id;
      });
      this.$axios
        .post(`company/roster/add/employee`, {
          employee_id: results,
        })
        .then((res) => {
          if (res.data.code === 200) {
            setTimeout(() => {
              this.$router.push({
                name: "employee.roster.index",
              });
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
          }
        }).catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        }).catch((error) => {
          this.isLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
        })
    },
  },

  created() {
    this.fetchDepartment();
    this.fetchBranch();
  },
};
</script>

<style scoped lang="scss">
.section-action {
  margin-top: 20px;
  margin-left: 20px;
}
.main-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;

  .section--header {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    .header-action {
      color: #18bbec;
      font-size: 24px;
      font-family: $font-family;
      margin-top: 20px;
      margin-left: 40px;

      span {
        margin-right: 10px;
        color: #18bbec;
        font-size: 30px;
      }

      .btn-right {
        position: absolute;
        right: 40px;
        top: 30px;
      }
    }
  }

  .section--content {
    width: 100%;
    height: auto;
    display: flex;

    .section-left {
      flex: 1;
      padding: 40px;

      .form-autocomplete {
      }
    }

    .section-right {
      flex: 1;
      padding: 30px 40px 0 0;

      .form-autocomplete {
        margin-top: 20px;
      }

      .image-circle-emp-position img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px;
      }
    }
  }
}
</style>
